<div class="grid-fluid">
  <div class="footer-block" *ngFor="let node of nodes">
    <h3>{{node.title}}</h3>
    <ul>
      <li *ngFor="let item of node.children">
        <a class="link" [href]="item.url" [title]="item.tooltip || item.title">{{ item.title }}</a>
      </li>
    </ul>
  </div>
</div>

<p>
  Superalimentado por Google LLC ©2020.
  Código licenciado bajo un <a href="license" title="Texto completo de la licencia">Licencia de estilo MIT</a>.
  Documentación bajo licencia
  <a href="http://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>.
</p>
<p>
  Versión {{versionInfo?.full}}.
</p>
